import { createInertiaApp } from '@inertiajs/react';
import { createRoot } from 'react-dom/client';
import React from 'react';
import * as Sentry from '@sentry/react';
import { ThemeProvider as StyledComponentThemeProvider } from 'styled-components';
import GlobalStyle from './components/v1/GlobalStyle.jsx';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { DialogProvider } from '@/contexts/DialogContext.jsx';
import { DrawerProvider } from '@/contexts/DrawerContext.jsx';
import { ModalProvider } from '@/contexts/ModalContext.jsx';
import '@/css/app.css';
import { PopupProvider } from '@/contexts/PopupContext.jsx';
import ThemeConfig from '@/configs/theme.config.js';
import { QueryClient, QueryClientProvider } from 'react-query';
import GoogleTagManager from 'react-gtm-module';
import { ThemeProvider } from '@/contexts/ThemeContext.jsx';

const queryClient = new QueryClient();

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://2e05198413e84901b84253f762701299@o1165738.ingest.sentry.io/4504129475575808',
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        environment: import.meta.env.VITE_APP_ENV,
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.0,
        replaysOnErrorSampleRate: 1.0,
    });

    GoogleTagManager.initialize({ gtmId: 'GTM-W9F7X5JR' });
}

createInertiaApp({
    title: (title) => title,
    resolve: (name) => resolvePageComponent(`./pages/${name}.jsx`, import.meta.glob('./pages/**/*.jsx')),
    progress: { delay: 300, color: '#A9C1B7', showSpinner: false },
    setup: ({ el, App, props }) => {
        const presets = props.initialPage.props.presets;

        createRoot(el).render(
            <QueryClientProvider client={queryClient}>
                <StyledComponentThemeProvider theme={ThemeConfig}>
                    <PopupProvider presets={presets}>
                        <DialogProvider presets={presets}>
                            <DrawerProvider presets={presets}>
                                <ModalProvider presets={presets}>
                                    <GlobalStyle />
                                    <App {...props} />
                                </ModalProvider>
                            </DrawerProvider>
                        </DialogProvider>
                    </PopupProvider>
                </StyledComponentThemeProvider>
            </QueryClientProvider>,
        );
    },
});
