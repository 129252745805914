import TextField from '../fields/TextField.jsx';
import Typography from '../Typography.jsx';
import EmailField from '../fields/EmailField.jsx';
import Box from '../Box.jsx';
import { useMemo, useState } from 'react';
import * as Formatter from '@/utils/old/FormatterUtil.js';
import AutocompleteField from '@/components/v1/fields/AutocompleteField.jsx';
import Select from '@/components/v1/Select.jsx';

const CompanyUserForm = ({ form, onSubmit, users, company, presets, existingUser, hidden = [], ...properties }) => {
    const [options, setOptions] = useState({
        salutations: presets.salutations.map((salutation) => ({
            title: Formatter.salutation(salutation),
            value: salutation,
        })),
    });
    const data = useMemo(() => form.data, [form.data]);
    const errors = useMemo(() => form.errors, [form.errors]);

    const handleFormChange = (event) => {
        const target = event.target;
        const { name, value } = target;

        form.setData({ ...data, [name]: value });
    };

    const excludedUsers = useMemo(() => {
        return users?.map((user) => user.id);
    }, [users]);

    const handleAutocompleteFormChange = (event) => {
        const value = event.target.value;

        form.setData({ ...data, ...value });
    };

    return (
        <Box as={'form'} onSubmit={onSubmit} {...properties}>
            {!existingUser ? (
                <Box display={'grid'} gridTemplateColumns={{ xs: '1fr', xxl: '1fr 1fr' }} gridGap={24}>
                    <div className={'flex flex-col gap-2'}>
                        <Typography>Aanhef*</Typography>

                        <Select name={'salutation'} value={data?.salutation} options={options.salutations} onChange={handleFormChange} />

                        {errors?.salutation && <Typography color={'error.base'}>{errors.salutation}</Typography>}
                    </div>

                    <div className={'flex flex-col gap-2'}>
                        <Typography>Voornaam*</Typography>

                        <TextField name={'first_name'} type={'text'} value={data?.first_name} error={errors?.first_name} onChange={handleFormChange} />

                        {errors?.first_name && <Typography color={'error.base'}>{errors.first_name}</Typography>}
                    </div>

                    <div className={'flex flex-col gap-2'}>
                        <Typography>Achternaam*</Typography>

                        <TextField name={'last_name'} type={'text'} value={data?.last_name} error={errors?.last_name} onChange={handleFormChange} />

                        {errors?.last_name && <Typography color={'error.base'}>{errors.last_name}</Typography>}
                    </div>

                    <div className={'flex flex-col gap-2'}>
                        <Typography>E-mailadres*</Typography>

                        <EmailField name={'email'} value={data?.email} error={errors?.email} onChange={handleFormChange} />

                        {errors?.email && <Typography color={'error.base'}>{errors.email}</Typography>}
                    </div>

                    <div className={'flex flex-col gap-2'}>
                        <Typography>Telefoonnummer</Typography>

                        <TextField name={'phone'} value={data?.phone} error={errors?.phone} onChange={handleFormChange} />

                        {errors?.phone && <Typography color={'error.base'}>{errors.phone}</Typography>}
                    </div>

                    <div className={'flex flex-col gap-2'}>
                        <Typography>Geboortedatum</Typography>

                        <TextField name={'date_of_birth'} value={data?.date_of_birth} error={errors?.date_of_birth} onChange={handleFormChange} type={'date'} />

                        {errors?.date_of_birth && <Typography color={'error.base'}>{errors.date_of_birth}</Typography>}
                    </div>

                    {!hidden.includes('label') && (
                        <div className={'flex flex-col gap-2'}>
                            <Typography>Label</Typography>

                            <AutocompleteField name={'label'} value={data?.label} model={'Label'} onChange={handleFormChange} renderTitle={(option) => option.name} />

                            {errors?.label?.id && <Typography color={'error.base'}>{errors.label?.id}</Typography>}
                        </div>
                    )}
                </Box>
            ) : (
                <Box>
                    <AutocompleteField name={'user'} value={data} model={'User'} onChange={handleAutocompleteFormChange} renderTitle={(option) => option.full_name} exclude={excludedUsers} />
                </Box>
            )}

            <input type={'submit'} hidden />
        </Box>
    );
};

export default CompanyUserForm;
