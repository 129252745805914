import { space } from 'styled-system';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import Typography from '../Typography.jsx';
import * as Formatter from '../../../utils/old/FormatterUtil.js';

const DocumentField = ({ name, value, error, onChange, ...properties }) => {
    const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
        accept: {
            'image/*': [],
            'application/pdf': [],
        },
        multiple: false,
        maxSize: Math.pow(1024, 2) * 4, // 4mb
        onDrop: (files) => onChange({ target: { name, value: files[0] } }),
    });

    return (
        <>
            <Component active={isDragActive} error={error} {...getRootProps()} {...properties}>
                <input {...getInputProps()} />

                <Typography className={'text-xl font-bold'} mt={24}>
                    {value ? value.name : 'Document selecteren'}
                </Typography>

                <Typography color={'grey.9'}>{value ? Formatter.bytes(value.size) : 'png, jpeg, jpg'}</Typography>
            </Component>

            {fileRejections.length > 0 && <Typography color={'error.base'}>Bestand is te groot. Maximale grootte is 4MB.</Typography>}
        </>
    );
};

const Component = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 24px;
    width: 100%;
    background: ${({ theme: { colors } }) => colors.background.variant};
    border: 1px solid ${({ theme, error }) => (error ? theme.colors.error.base : theme.colors.grey[1])};
    border-radius: 0.5rem;
    cursor: pointer;
    transition: filter ease-in-out 100ms;
    ${space}

    &:hover {
        filter: brightness(95%);
    }
`;

export default DocumentField;
