import Dialog from '@/components/v1/Dialog.jsx';
import Typography from '@/components/v1/Typography.jsx';
import Button from '@/components/v1/Button.jsx';
import Box from '@/components/v1/Box.jsx';
import { CloudArrowUpIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import LoadingOverlay from '@/components/v1/overlays/LoadingOverlay.jsx';
import { router } from '@inertiajs/react';

const InvestorSendToHubspotDialog = ({ onClose, investor }) => {
    const [processing, setProcessing] = useState(false);

    const handleCreateContract = () => {
        setProcessing(true);

        router.post(route('investors.send-to-hubspot', { investor: investor.id }), null, {
            onSuccess: () => onClose(),
            onFinish: setProcessing(false),
            preserveScroll: true,
        });
    };

    return (
        <Dialog onClose={onClose}>
            <LoadingOverlay loading={processing} />

            <Typography className={'text-xl font-bold'} textAlign={'center'}>
                Toevoegen aan Hubspot
            </Typography>

            <Typography textAlign={'center'}>Weet u zeker dat u deze investeerder wilt toevoegen aan Hubspot?</Typography>

            <Box mt={24} display={'flex'} justifyContent={'center'} gridGap={24}>
                <Button onClick={handleCreateContract}>
                    <CloudArrowUpIcon width={24} />
                    <span className={'font-bold'}>Investeerder toevoegen</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Dialog>
    );
};

export default InvestorSendToHubspotDialog;
