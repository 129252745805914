import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { XMarkIcon } from '@heroicons/react/24/outline';

const classNames = {
    sizes: {
        small: 'h-8 px-2 rounded-md',
        normal: 'h-14 px-4 rounded-lg',
    },
};

const Chip = ({ label, onRemove }) => (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }} className='flex h-9 items-center gap-2 rounded-lg bg-[#F0F0F0] px-3'>
        <span className='whitespace-nowrap text-sm'>{label}</span>
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} onClick={onRemove} className='flex items-center justify-center'>
            <XMarkIcon className='size-4' />
        </motion.div>
    </motion.div>
);

const Select = ({ name, options, value, onChange, multiple, className, disabled, renderTitle, renderDescription, placeholder, size = 'normal', ...properties }) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const [isFocused, setIsFocused] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState('below'); // 'below' or 'above'
    const selectRef = useRef(null);
    const dropdownRef = useRef(null);
    const optionsRef = useRef([]);

    const selectedOptions = useMemo(() => {
        if (multiple) {
            return Array.isArray(value) ? (value || []).map((v) => options?.find((option) => option?.value === v)).filter(Boolean) : [];
        } else {
            return value ? [options?.find((option) => option?.value === value)].filter(Boolean) : [];
        }
    }, [value, multiple, options]);

    const availableOptions = useMemo(() => {
        return (options || []).filter((option) => !selectedOptions.some((selectedOption) => selectedOption?.value === option?.value));
    }, [options, selectedOptions]);

    const updateDropdownPosition = useCallback(() => {
        if (selectRef.current && dropdownRef.current) {
            const selectRect = selectRef.current.getBoundingClientRect();
            const dropdownHeight = dropdownRef.current.offsetHeight;
            const viewportHeight = window.innerHeight;

            // Calculate available space
            const spaceBelow = viewportHeight - selectRect.bottom;
            const spaceAbove = selectRect.top;

            // If not enough space below and more space above, position above
            setDropdownPosition(spaceBelow < dropdownHeight && spaceAbove > dropdownHeight ? 'above' : 'below');
        }
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setDropdownOpen(false);
                setIsFocused(false);
            }
        };

        window.addEventListener('mousedown', handleClickOutside);
        return () => window.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        if (isDropdownOpen) {
            setFocusedIndex(0);
            updateDropdownPosition();
        } else {
            setFocusedIndex(-1);
        }
    }, [isDropdownOpen, updateDropdownPosition]);

    useEffect(() => {
        const handleResize = () => {
            if (isDropdownOpen) {
                updateDropdownPosition();
            }
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleResize, true);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleResize, true);
        };
    }, [isDropdownOpen, updateDropdownPosition]);

    const handleOptionSelect = useCallback(
        (option) => {
            if (multiple) {
                const updatedValue = [...(value || []), option.value];
                onChange({ target: { name, value: updatedValue } });
            } else {
                onChange({ target: { name, value: option.value } });
                setDropdownOpen(false);
            }
            selectRef.current?.focus();
        },
        [onChange, name, multiple, value],
    );

    const handleOptionRemove = useCallback(
        (optionValue) => {
            if (multiple) {
                const updatedValue = Array.isArray(value) ? value.filter((v) => v !== optionValue) : [];
                onChange({ target: { name, value: updatedValue } });
            } else {
                onChange({ target: { name, value: null } });
            }
        },
        [onChange, name, multiple, value],
    );

    const handleDropdownToggle = useCallback(() => {
        if (!disabled) {
            setDropdownOpen((prev) => !prev);
            setIsFocused(true);
        }
    }, [disabled]);

    const handleKeyboardNavigation = useCallback(
        (event) => {
            if (disabled) return;

            switch (event.key) {
                case 'ArrowDown':
                case 'ArrowUp': {
                    event.preventDefault();
                    if (!isDropdownOpen) {
                        setDropdownOpen(true);
                        setFocusedIndex(0);
                    } else {
                        const step = event.key === 'ArrowDown' ? 1 : -1;
                        const newIndex = (focusedIndex + step + availableOptions.length) % availableOptions.length;
                        setFocusedIndex(newIndex);
                        optionsRef.current[newIndex]?.scrollIntoView({
                            behavior: 'auto',
                            block: 'nearest',
                        });
                    }
                    break;
                }
                case 'Enter': {
                    event.preventDefault();
                    if (isDropdownOpen && focusedIndex !== -1 && availableOptions[focusedIndex]) {
                        handleOptionSelect(availableOptions[focusedIndex]);
                    } else {
                        setDropdownOpen(true);
                    }
                    break;
                }
                case 'Escape': {
                    setDropdownOpen(false);
                    break;
                }
                case 'Tab': {
                    if (isDropdownOpen) {
                        event.preventDefault();
                        const newIndex = (focusedIndex + 1) % availableOptions.length;
                        setFocusedIndex(newIndex);
                    }
                    break;
                }
            }
        },
        [disabled, isDropdownOpen, focusedIndex, availableOptions, handleOptionSelect],
    );

    const handleFocus = useCallback(() => {
        if (!disabled) {
            setIsFocused(true);
        }
    }, [disabled]);

    const handleBlur = useCallback(() => {
        setTimeout(() => {
            if (!selectRef.current?.contains(document.activeElement)) {
                setIsFocused(false);
                setDropdownOpen(false);
            }
        }, 0);
    }, []);

    return (
        <div className={twMerge('relative', className)} ref={selectRef} onKeyDown={handleKeyboardNavigation} tabIndex={disabled ? -1 : 0} onFocus={handleFocus} onBlur={handleBlur} {...properties}>
            <div
                className={twMerge(
                    `${classNames.sizes[size]} text-deep-black w-full bg-pure-white border border-grey-200 focus:border-primary-500 focus:outline-none`,
                    'flex items-center',
                    disabled ? 'bg-grey-50 cursor-not-allowed' : 'cursor-pointer',
                    isFocused && !disabled ? 'border-primary-500' : '',
                    className,
                )}
                onClick={handleDropdownToggle}
            >
                <div className='relative grow overflow-hidden'>
                    <div className='no-scrollbar flex items-center gap-2 overflow-x-auto'>
                        <AnimatePresence mode='wait'>
                            {selectedOptions.length > 0 ? selectedOptions.map((option) => <Chip key={option?.value} label={renderTitle ? renderTitle(option) : option?.title} onRemove={() => handleOptionRemove(option?.value)} />) : <span className='text-grey-400'>{placeholder}</span>}
                        </AnimatePresence>
                    </div>
                </div>
                <div className='ml-2 shrink-0'>
                    <motion.svg className='aspect-square h-5 text-grey-300' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' animate={{ rotate: isDropdownOpen ? 180 : 0 }} transition={{ duration: 0.2 }}>
                        <path fillRule='evenodd' d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' clipRule='evenodd' />
                    </motion.svg>
                </div>
            </div>

            <AnimatePresence>
                {isDropdownOpen && (
                    <motion.div
                        ref={dropdownRef}
                        initial={{ opacity: 0, y: dropdownPosition === 'below' ? 10 : -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: dropdownPosition === 'below' ? 10 : -10 }}
                        transition={{ duration: 0.2 }}
                        className='absolute z-20 w-full overflow-hidden rounded-md border border-grey-200 bg-pure-white drop-shadow-lg'
                        style={{
                            [dropdownPosition === 'below' ? 'top' : 'bottom']: '100%',
                            maxHeight: '80vh',
                        }}
                    >
                        <div className='max-h-80 overflow-y-auto' role='listbox'>
                            {availableOptions.map((option, index) => (
                                <motion.div
                                    key={option.value}
                                    ref={(el) => (optionsRef.current[index] = el)}
                                    className={`flex cursor-pointer items-center justify-between p-4
                                        ${index === focusedIndex ? 'bg-[#F8F8F8]' : 'hover:bg-[#F8F8F8]'}
                                        ${index === 0 ? 'rounded-t-lg' : ''}
                                        ${index === availableOptions.length - 1 ? 'rounded-b-lg' : ''}
                                    `}
                                    onClick={() => handleOptionSelect(option)}
                                    role='option'
                                    aria-selected={false}
                                >
                                    <div className='flex flex-col'>
                                        <p>{renderTitle ? renderTitle(option) : option.title}</p>
                                        {renderDescription && <p className='text-grey-600'>{renderDescription(option)}</p>}
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Select;
