import Dialog from '@/components/v1/Dialog.jsx';
import Typography from '@/components/v1/Typography.jsx';
import Button from '@/components/v1/Button.jsx';
import Box from '@/components/v1/Box.jsx';
import { ArrowPathRoundedSquareIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import LoadingOverlay from '@/components/v1/overlays/LoadingOverlay.jsx';
import CollateralObjectRefetchForm from '@/components/v1/forms/ApplicationCollateralObjectRefreshForm.jsx';
import { useForm } from '@inertiajs/react';

const ApplicationCollateralObjectRefreshDialog = ({ onClose, collateral_object, presets }) => {
    const form = useForm();
    const [processing, setProcessing] = useState(false);
    const handleApplicationDelete = () => {
        setProcessing(true);
        form.submit(
            'put',
            route('collateral-objects.refetch-report', {
                collateral_object: collateral_object.id,
            }),
            {
                onSuccess: () => onClose(),
                onFinish: () => setProcessing(false),
                preserveScroll: true,
            },
        );
    };

    return (
        <Dialog onClose={onClose}>
            <LoadingOverlay loading={processing} />

            <Typography className={'text-center text-xl font-bold'}>Eigendom herladen</Typography>

            <Typography className={'text-center'}>Kies welk onderdeel u wilt herladen:</Typography>

            <CollateralObjectRefetchForm form={form} mt={24} />

            <Box mt={24} display={'flex'} justifyContent={'center'} gridGap={24}>
                <Button onClick={handleApplicationDelete}>
                    <ArrowPathRoundedSquareIcon width={24} />
                    <span className={'font-bold'}>Eigendom herladen</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Dialog>
    );
};

export default ApplicationCollateralObjectRefreshDialog;
