import { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import axios from 'axios';
import useSWR from 'swr';

const fetcher = (url, data) => axios.post(url, data).then((response) => response.data);

const AutocompleteField = ({ name, value, placeholder, onChange, model, filters, exclude, multiple, className, isDisabled, renderTitle, renderDescription, ...properties }) => {
    const [inputValue, setInputValue] = useState('');
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOrientation, setDropdownOrientation] = useState('down');
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
    const autocompleteRef = useRef(null);
    const inputRef = useRef(null);
    const options = useSWR(
        route('search.autocomplete', {
            query: inputValue,
            model,
            filters,
            exclude,
        }),
        fetcher,
    );

    useEffect(() => {
        const checkDropdownPosition = () => {
            if (autocompleteRef.current) {
                const rect = autocompleteRef.current.getBoundingClientRect();
                const dropdownElement = autocompleteRef.current.querySelector('.dropdown-content');
                const dropdownHeight = dropdownElement ? dropdownElement.offsetHeight : 0;
                const spaceBelow = window.innerHeight - rect.bottom;
                const spaceAbove = rect.top;

                setDropdownOrientation(spaceBelow < dropdownHeight && spaceAbove > spaceBelow ? 'up' : 'down');
            }
        };

        window.addEventListener('resize', checkDropdownPosition);
        window.addEventListener('scroll', checkDropdownPosition);
        checkDropdownPosition();

        return () => {
            window.removeEventListener('resize', checkDropdownPosition);
            window.removeEventListener('scroll', checkDropdownPosition);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isDropdownOpen) {
            inputRef.current.focus();
        }
    }, [isDropdownOpen]);

    const handleOptionSelect = (optionValue) => {
        if (!isDropdownOpen) return;

        const selectedOption = options.data.find((option) => option.id === optionValue);

        if (multiple) {
            const updatedValue = Array.isArray(value) ? value : [];

            if (updatedValue.some((v) => v.id === optionValue)) {
                onChange({
                    target: {
                        name,
                        value: updatedValue.filter((v) => v.id !== optionValue),
                    },
                });
            } else {
                onChange({
                    target: { name, value: [...updatedValue, selectedOption] },
                });
            }
        } else {
            onChange({ target: { name, value: selectedOption } });
            setDropdownOpen(false);
        }
    };

    const handleOptionRemove = (optionId) => {
        const updatedValue = multiple ? value.filter((option) => option.id !== optionId) : null;
        onChange({ target: { name, value: updatedValue } });
    };

    const isOptionSelected = (optionValue) => {
        return multiple ? value?.some((v) => v.id === optionValue) : value?.id === optionValue;
    };

    const handleDropdownClick = () => {
        if (isDisabled) return;

        setDropdownOpen(!isDropdownOpen);
    };

    const handleKeyboardNavigation = (event) => {
        if (!isDropdownOpen) {
            if (event.key === 'Enter') {
                setDropdownOpen(true);
            }
            return;
        }

        switch (event.key) {
            case 'ArrowDown':
                setSelectedOptionIndex((prevIndex) => (prevIndex + 1) % options.data.length);
                break;
            case 'ArrowUp':
                setSelectedOptionIndex((prevIndex) => (prevIndex - 1 + options.data.length) % options.data.length);
                break;
            case 'Enter':
                handleOptionSelect(options[selectedOptionIndex].id);
                break;
            case 'Escape':
                setDropdownOpen(false);
                break;
            default:
                break;
        }
    };

    const chevronVariants = {
        open: { rotate: 180 },
        closed: { rotate: 0 },
    };

    return (
        <div className={twMerge('relative', className)} ref={autocompleteRef} onKeyDown={handleKeyboardNavigation} tabIndex={0} {...properties}>
            <div className={`flex h-14 w-full cursor-pointer items-center overflow-hidden rounded-md border border-solid border-grey-200 bg-pure-white px-4 transition duration-100`} onClick={handleDropdownClick}>
                <div className={'no-scrollbar flex grow items-center gap-0.5 overflow-x-auto'}>
                    {Array.isArray(value)
                        ? value?.map((option, optionIdx) => (
                              <div key={optionIdx} className='mr-2 flex items-center whitespace-nowrap rounded bg-grey-100 px-2 py-1 text-sm font-semibold text-grey-900 hover:text-grey-700'>
                                  <p>{renderTitle ? renderTitle(option) : option.title}</p>

                                  <button className='ml-2' onClick={() => handleOptionRemove(option.id)}>
                                      &times;
                                  </button>
                              </div>
                          ))
                        : value && <p>{renderTitle ? renderTitle(value) : value?.title}</p>}
                </div>

                <motion.svg className={'aspect-square h-5'} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' variants={chevronVariants} animate={isDropdownOpen ? 'open' : 'closed'}>
                    <path fillRule='evenodd' d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' clipRule='evenodd' />
                </motion.svg>
            </div>

            <AnimatePresence>
                {isDropdownOpen && (
                    <div className={`absolute right-0 z-20 w-full rounded border border-solid border-grey-200 bg-pure-white shadow-md ${dropdownOrientation === 'up' ? 'bottom-full mb-2' : 'top-full mt-2'}`}>
                        <input ref={inputRef} className='h-14 w-full border-0 border-b border-solid border-grey-200 bg-transparent px-4' value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder={placeholder} />

                        <div className='max-h-48 overflow-auto p-2' role='listbox'>
                            {options.isLoading ? (
                                <div className='p-4'>Laden...</div>
                            ) : options?.data?.length > 0 ? (
                                options?.data?.map((option, index) => (
                                    <div
                                        key={option.id}
                                        className={`flex cursor-pointer items-center justify-between gap-4 rounded bg-transparent p-4 text-deep-black transition duration-100 hover:brightness-95 ${index === selectedOptionIndex && 'bg-primary-100'}`}
                                        onClick={() => handleOptionSelect(option.id)}
                                        role='option'
                                        aria-selected={isOptionSelected(option.id)}
                                    >
                                        <div className={'flex flex-col gap-1'}>
                                            <p>{renderTitle ? renderTitle(option) : option.title}</p>

                                            {renderDescription && <p className={'text-sm text-grey-900'}>{renderDescription(option)}</p>}
                                        </div>

                                        <AnimatePresence>{isOptionSelected(option.id) && <motion.div initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.5 }} className='aspect-square h-3 rounded-full bg-primary-400' />}</AnimatePresence>
                                    </div>
                                ))
                            ) : (
                                <div className='p-4 text-grey-500'>Geen resultaten gevonden</div>
                            )}
                        </div>
                    </div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default AutocompleteField;
