import TextField from '../fields/TextField.jsx';
import Typography from '../Typography.jsx';
import Box from '../Box.jsx';
import CurrencyField from '@/components/v1/fields/CurrencyField.jsx';
import { useMemo } from 'react';
import AutocompleteField from '@/components/v1/fields/AutocompleteField.jsx';
import SelectField from '@/components/v1/fields/SelectField.jsx';

const InvestorBondForm = ({ form, bond, onSubmit, ...properties }) => {
    const data = useMemo(() => form.data, [form.data]);
    const errors = useMemo(() => form.errors, [form.errors]);

    const handleFormChange = (event) => {
        const target = event.target;
        const { name, value } = target;

        form.setData({ ...data, [name]: value });
    };

    return (
        <Box as={'form'} onSubmit={onSubmit} {...properties}>
            <div className={'flex flex-col gap-4'}>
                {(!bond || bond?.state?.value === 'processing') && (
                    <div className={'flex flex-col gap-2'}>
                        <Typography>Tranche*</Typography>

                        <AutocompleteField name={'tranche'} value={data?.tranche} model={'Tranche'} onChange={handleFormChange} renderTitle={(option) => option.name} />

                        {errors?.tranche?.id && <Typography color={'error.base'}>{errors.tranche?.id}</Typography>}
                    </div>
                )}

                {(!bond || bond?.state?.value === 'processing') && (
                    <div className={'flex flex-col gap-2'}>
                        <Typography>Aantal*</Typography>

                        <TextField name={'quantity'} value={data?.quantity} error={errors?.quantity} onChange={handleFormChange} type={'number'} />

                        {errors?.quantity && <Typography color={'error.base'}>{errors.quantity}</Typography>}
                    </div>
                )}

                {bond?.transitionable_states?.length > 0 && (
                    <div className={'flex flex-col gap-2'}>
                        <Typography>Status*</Typography>

                        <SelectField name={'state'} value={data?.state} error={errors?.state} options={bond.transitionable_states} onChange={handleFormChange} />

                        {errors?.state && <Typography color={'error.base'}>{errors.state}</Typography>}
                    </div>
                )}

                {(!bond || bond?.state?.value === 'processing') && (
                    <div className={'flex flex-col gap-2'}>
                        <Typography>Waarde*</Typography>

                        <CurrencyField name={'amount'} type={'text'} value={data?.amount} error={errors?.amount} onChange={handleFormChange} />

                        {errors?.amount && <Typography color={'error.base'}>{errors.amount}</Typography>}
                    </div>
                )}
            </div>

            <input type={'submit'} hidden />
        </Box>
    );
};

export default InvestorBondForm;
