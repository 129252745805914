import TextField from '../fields/TextField.jsx';
import Typography from '../Typography.jsx';
import Box from '../Box.jsx';
import { useMemo } from 'react';
import PercentageField from '@/components/v1/fields/PercentageField.jsx';
import AutocompleteField from '@/components/v1/fields/AutocompleteField.jsx';
import ErrorMessage from '@/components/v1/ErrorMessage.jsx';

const TrancheForm = ({ form, onSubmit, ...properties }) => {
    const data = useMemo(() => form.data, [form.data]);
    const errors = useMemo(() => form.errors, [form.errors]);

    const handleFormChange = (event) => {
        const target = event.target;
        const { name, value } = target;

        form.setData({ ...data, [name]: value });
    };

    return (
        <Box as={'form'} onSubmit={onSubmit} {...properties}>
            <Box mt={24} display={'grid'} gridTemplateColumns={{ xs: '1fr', xxl: '1fr 1fr' }} gridGap={24}>
                <div className={'flex flex-col gap-2'}>
                    <Typography>Naam*</Typography>

                    <TextField name={'name'} type={'text'} value={data?.name} error={errors?.name} onChange={handleFormChange} />

                    {errors?.name && <Typography color={'error.base'}>{errors.name}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Percentage*</Typography>

                    <PercentageField name={'interest_percentage'} value={data?.interest_percentage} onChange={handleFormChange} />

                    {errors?.interest_percentage && <Typography color={'error.base'}>{errors.interest_percentage}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Start datum*</Typography>

                    <TextField name={'starts_at'} value={data?.starts_at} error={errors?.starts_at} onChange={handleFormChange} type={'date'} />

                    {errors?.starts_at && <Typography color={'error.base'}>{errors.starts_at}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Eind datum*</Typography>

                    <TextField name={'ends_at'} value={data?.ends_at} error={errors?.ends_at} onChange={handleFormChange} type={'date'} />

                    {errors?.ends_at && <Typography color={'error.base'}>{errors.ends_at}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Propositie*</Typography>

                    <AutocompleteField name={'proposition_phase'} value={data?.proposition_phase} model={'PropositionPhase'} filters={{ proposition_id: data?.company_id }} onChange={handleFormChange} renderTitle={(option) => `${option.proposition.title} (${option.name})`} />

                    <ErrorMessage message={errors.proposition_phase?.id} />
                </div>
            </Box>

            <input type={'submit'} hidden />
        </Box>
    );
};

export default TrancheForm;
