import Dialog from '@/components/v1/Dialog.jsx';
import Typography from '@/components/v1/Typography.jsx';
import Button from '@/components/v1/Button.jsx';
import Box from '@/components/v1/Box.jsx';
import { TrashIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import LoadingOverlay from '@/components/v1/overlays/LoadingOverlay.jsx';
import { router } from '@inertiajs/react';

const ApplicationSourceDeleteDialog = ({ onClose, source }) => {
    const [processing, setProcessing] = useState(false);
    const handleApplicationDelete = () => {
        setProcessing(true);
        router.delete(
            route('settings.application-sources.destroy', {
                application_source: source.id,
            }),
            {
                onFinish: setProcessing(false),
                onSuccess: () => onClose(),
                preserveScroll: true,
            },
        );
    };

    return (
        <Dialog onClose={onClose}>
            <LoadingOverlay loading={processing} />

            <Typography className={'text-xl font-bold'} textAlign={'center'}>
                Partner widget verwijderen
            </Typography>

            <Typography textAlign={'center'}>Weet u zeker dat u deze partner widget wilt verwijderen?</Typography>
            <Typography textAlign={'center'}>De widget is na deze actie niet meer actief.</Typography>

            <Box mt={24} display={'flex'} justifyContent={'center'} gridGap={24}>
                <Button onClick={handleApplicationDelete}>
                    <TrashIcon width={24} />
                    <span className={'font-bold'}>Partner widget verwijderen</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Dialog>
    );
};

export default ApplicationSourceDeleteDialog;
