import Slider from 'rc-slider';

const RangeSlider = ({ name, min = 12500, max = 200000, step = 500, value, onChange }) => {
    const handleRangeChange = (value) => {
        onChange({ target: { name, value } });
    };

    return <Slider min={min} max={max} step={step} value={value} onChange={handleRangeChange} />;
};

export default RangeSlider;
