import Dialog from '@/components/v1/Dialog.jsx';
import Typography from '@/components/v1/Typography.jsx';
import Button from '@/components/v1/Button.jsx';
import Box from '@/components/v1/Box.jsx';
import { ArrowsRightLeftIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import LoadingOverlay from '@/components/v1/overlays/LoadingOverlay.jsx';
import { router } from '@inertiajs/react';

const UserDetachDialog = ({ onClose, user, company }) => {
    const [processing, setProcessing] = useState(false);
    const handleDetachUserClick = () => {
        setProcessing(true);
        router.delete(route('companies.users.destroy', { user: user.id, company: company.id }), {
            onFinish: () => setProcessing(false),
            onSuccess: () => onClose(),
            preserveScroll: true,
        });
    };

    return (
        <Dialog onClose={onClose}>
            <LoadingOverlay loading={processing} />

            <Typography className={'text-xl font-bold'} textAlign={'center'}>
                Gebruiker ontkoppelen
            </Typography>

            <Typography textAlign={'center'}>Weet u zeker dat u deze gebruiker wilt ontkoppelen?</Typography>

            <Box mt={24} display={'flex'} justifyContent={'center'} gridGap={24}>
                <Button onClick={handleDetachUserClick}>
                    <ArrowsRightLeftIcon width={24} />
                    <span className={'font-bold'}>Gebruiker ontkoppelen</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Dialog>
    );
};

export default UserDetachDialog;
