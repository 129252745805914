import Typography from '../Typography.jsx';
import TextField from '../fields/TextField.jsx';
import EmailField from '../fields/EmailField.jsx';
import PhoneField from '../fields/PhoneField.jsx';
import { twMerge } from 'tailwind-merge';
import * as Formatter from '@/utils/old/FormatterUtil.js';
import Select from '@/components/v1/Select.jsx';

const ApplicationRequestCompanyForm = ({ data, setData, errors, className, presets, ...properties }) => {
    const options = {
        salutations: presets.salutations.map((salutation) => ({
            title: Formatter.salutation(salutation),
            value: salutation,
        })),
    };

    const handleFormChange = (event) => {
        const { name, value } = event.target;

        setData((previousData) => ({
            ...previousData,
            user: { ...previousData?.user, [name]: value },
        }));
    };

    return (
        <form className={twMerge('flex flex-col gap-4', className)} {...properties}>
            <div className={'flex flex-col gap-2'}>
                <Typography>Aanhef</Typography>

                <Select name={'salutation'} value={data?.user?.salutation} error={errors?.user?.salutation} options={options.salutations} onChange={handleFormChange} />

                {errors?.user?.salutation && <Typography color={'error.base'}>{errors?.user?.salutation}</Typography>}
            </div>

            <div className={'grid grid-cols-2 gap-4'}>
                <div className={'flex flex-col gap-2'}>
                    <Typography>Voornaam</Typography>

                    <TextField name={'first_name'} value={data?.user?.first_name} onChange={handleFormChange} />

                    {errors?.user?.first_name && <Typography color={'error.base'}>{errors?.user?.first_name}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Achternaam</Typography>

                    <TextField name={'last_name'} value={data?.user?.last_name} onChange={handleFormChange} />

                    {errors?.user?.last_name && <Typography color={'error.base'}>{errors?.user?.last_name}</Typography>}
                </div>
            </div>

            <div className={'flex flex-col gap-2'}>
                <Typography>E-mailadres</Typography>

                <EmailField name={'email'} value={data?.user?.email} onChange={handleFormChange} />

                {errors?.user?.email && <Typography color={'error.base'}>{errors?.user?.email}</Typography>}
            </div>

            <div className={'flex flex-col gap-2'}>
                <Typography>Telefoonnummer</Typography>

                <PhoneField name={'phone'} value={data?.user?.phone} onChange={handleFormChange} />

                {errors?.user?.phone && <Typography color={'error.base'}>{errors?.user?.phone}</Typography>}
            </div>
        </form>
    );
};

export default ApplicationRequestCompanyForm;
