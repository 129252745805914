import ApplicationWidgetGeneralForm from '@/components/v1/forms/ApplicationWidgetGeneralForm.jsx';
import ApplicationWidgetCollateralObjectForm from '@/components/v1/forms/ApplicationWidgetCollateralObjectForm.jsx';
import ApplicationWidgetCompanyForm from '@/components/v1/forms/ApplicationWidgetCompanyForm.jsx';
import ApplicationRequestUserForm from '@/components/v1/forms/ApplicationWidgetUserForm.jsx';
import ApplicationWidgetDeclarationForm from '@/components/v1/forms/ApplicationWidgetDeclarationForm.jsx';

export default {
    steps: [
        {
            title: 'Financieringsbehoefte',
            description: 'Check direct of u in aanmerking komt door de vragen hieronder te beantwoorden. Komt u in aanmerking? Dan nemen we binnen 48 uur contact met u op om de mogelijkheden op maat te bespreken.',
            Form: ApplicationWidgetGeneralForm,
        },
        {
            title: 'Uw vastgoed',
            description: 'Wij bedoelen met eigendom bijvoorbeeld een eigen woning, bedrijfs- of winkelpand, vastgoedbeleggingen of (percelen) grond.',
            Form: ApplicationWidgetCollateralObjectForm,
        },
        {
            title: 'Bedrijfsgegevens',
            description: 'Zoek in de Kamer van Koophandel hieronder op uw bedrijfsnaam. De gegevens van uw onderneming worden daarna automatisch overgenomen.',
            Form: ApplicationWidgetCompanyForm,
        },
        {
            title: 'Persoonsgegevens',
            description: 'Vul onderstaand de gegevens in waarop wij U kunnen bereiken.',
            Form: ApplicationRequestUserForm,
        },
        {
            title: 'Verklaring naar waarheid',
            description: 'Lees onderstaande verklaring zorgvuldig door en vink aan dat u akkoord gaat met de voorwaarden.',
            Form: ApplicationWidgetDeclarationForm,
        },
    ],
};
