import { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import axios from 'axios';
import useDebounceValue from '@/hooks/useDebounceValue.jsx';
import { useQuery } from 'react-query';
import Lottie from 'react-lottie';
import LoadingLottie from '../../../../../public/assets/lotties/setup.json';

const motions = {
    chevron: {
        variants: {
            open: { rotate: 180 },
            closed: { rotate: 0 },
        },
    },
};

const CompanyField = ({ name, value, onChange, className, disabled, ...properties }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOrientation, setDropdownOrientation] = useState('down');
    const autocompleteRef = useRef(null);
    const inputRef = useRef(null);
    const debouncedSearchTerm = useDebounceValue(searchTerm);
    const optionsQuery = useQuery({
        enabled: debouncedSearchTerm?.length > 0,
        queryKey: ['companies', debouncedSearchTerm],
        select: (response) => response.data?.data,
        queryFn: () => axios.get(route('search.kvk', { trade_name: debouncedSearchTerm })),
    });

    useEffect(() => {
        const checkDropdownPosition = () => {
            if (autocompleteRef.current) {
                const rect = autocompleteRef.current.getBoundingClientRect();
                const dropdownElement = autocompleteRef.current.querySelector('.dropdown-content');
                const dropdownHeight = dropdownElement ? dropdownElement.offsetHeight : 0;
                const spaceBelow = window.innerHeight - rect.bottom;
                const spaceAbove = rect.top;

                setDropdownOrientation(spaceBelow < dropdownHeight && spaceAbove > spaceBelow ? 'up' : 'down');
            }
        };

        window.addEventListener('resize', checkDropdownPosition);
        window.addEventListener('scroll', checkDropdownPosition);
        checkDropdownPosition();

        return () => {
            window.removeEventListener('resize', checkDropdownPosition);
            window.removeEventListener('scroll', checkDropdownPosition);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isDropdownOpen) {
            inputRef.current.focus();
        }
    }, [isDropdownOpen]);

    const handleOptionSelect = (optionIdx) => {
        if (!isDropdownOpen) return;

        const selectedOption = optionsQuery.data?.[optionIdx];

        onChange({ target: { name, value: selectedOption } });
        setDropdownOpen(false);
    };

    const handleDropdownClick = () => {
        if (disabled) return;

        setDropdownOpen(!isDropdownOpen);
    };

    return (
        <div className={twMerge('relative', className)} ref={autocompleteRef} tabIndex={0} {...properties}>
            <div className={`flex h-14 w-full items-center justify-between rounded-lg border border-solid border-grey-200 px-4 ${disabled ? 'bg-grey-50' : 'cursor-pointer bg-transparent'}`} onClick={handleDropdownClick}>
                <div className={'no-scrollbar flex grow items-center gap-2 overflow-x-auto'}>{value && <p className={'whitespace-nowrap'}>{value?.name}</p>}</div>

                <motion.svg className={'aspect-square h-6 text-grey-700'} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' animate={isDropdownOpen ? 'open' : 'closed'} {...motions.chevron}>
                    <path fillRule='evenodd' d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' clipRule='evenodd' />
                </motion.svg>
            </div>

            <AnimatePresence>
                {isDropdownOpen && (
                    <div className={`absolute z-10 mt-2 w-full overflow-hidden rounded-lg border border-solid border-grey-200 bg-pure-white drop-shadow-lg ${dropdownOrientation === 'up' ? 'bottom-full mb-2' : 'top-full mt-2'}`}>
                        <input ref={inputRef} type={'text'} className='h-14 w-full border-0 border-b border-solid border-grey-200 bg-transparent px-4 outline-none' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder='Zoeken...' autoComplete={'off'} />

                        <div className='max-h-48 overflow-auto' role='listbox'>
                            {optionsQuery.isLoading ? (
                                <div className='p-4'>
                                    <div className={'h-28'}>
                                        <Lottie
                                            options={{
                                                loop: true,
                                                animationData: LoadingLottie,
                                                autoplay: true,
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : optionsQuery?.data?.length > 0 ? (
                                optionsQuery?.data?.map((option, optionIdx) => (
                                    <div key={option.id} className={`flex cursor-pointer items-center justify-between p-4 hover:bg-grey-100`} onClick={() => handleOptionSelect(optionIdx)} role='option'>
                                        <div className={'flex flex-col'}>
                                            <p className={'font-semibold'}>{option?.name}</p>

                                            <p className={'text-grey-800'}>
                                                {option.registration_number} {option.street ? '-' : ''} {option.street} {option.city}
                                            </p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='p-4 text-grey-800'>Geen resultaten gevonden</div>
                            )}
                        </div>
                    </div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default CompanyField;
