import Modal from '@/components/v1/Modal.jsx';
import Typography from '@/components/v1/Typography.jsx';
import Button from '@/components/v1/Button.jsx';
import { useForm } from '@inertiajs/react';
import Box from '@/components/v1/Box.jsx';
import { PencilSquareIcon, XCircleIcon } from '@heroicons/react/24/outline';
import LoadingOverlay from '@/components/v1/overlays/LoadingOverlay.jsx';
import AddressField from '@/components/v1/fields/AddressField.jsx';
import Label from '@/components/v1/Label.jsx';
import ErrorMessage from '@/components/v1/ErrorMessage.jsx';
import Checkbox from '@/components/v1/Checkbox.jsx';
import React from 'react';

const ApplicationCollateralObjectEditorModal = ({ onClose, application, collateral_object, presets }) => {
    const defaultValues = { ...collateral_object };
    const form = useForm(defaultValues);

    const handleApplicationEditor = (event) => {
        event.preventDefault();
        if (collateral_object) {
            form.submit(
                'put',
                route('collateral-objects.update', {
                    collateral_object: collateral_object.id,
                }),
                {
                    onSuccess: () => onClose(),
                    preserveScroll: true,
                },
            );
        } else {
            form.submit(
                'post',
                route('applications.collateral-objects.store', {
                    application: application.id,
                }),
                {
                    onSuccess: () => onClose(),
                    preserveScroll: true,
                },
            );
        }
    };

    const handleFormChange = (event) => {
        const { name, value } = event.target;

        form.setData(name, value);
    };

    return (
        <Modal width={840} onClose={onClose}>
            <LoadingOverlay loading={form.processing} />

            <Typography className={'text-xl font-bold'}>Eigendom {collateral_object ? 'wijzigen' : 'toevoegen'}</Typography>

            <Typography>Vul onderstaand de gegevens van een eigendom in.</Typography>

            <div className={'mt-5 flex flex-col gap-2'}>
                <Label>Adres</Label>

                <AddressField
                    name={'address'}
                    value={form.data?.address}
                    onChange={(event) =>
                        form.setData((previousData) => ({
                            ...previousData,
                            address: event.target.value,
                        }))
                    }
                />

                <ErrorMessage message={form?.errors?.address ? 'Onjuist adres' : null} />
            </div>

            <div className={'mt-8 flex flex-col gap-2'}>
                <Checkbox name={'collateral_for_loan'} checked={form.data?.collateral_for_loan} onChange={handleFormChange} label='Handmatig tonen aan investeerders ' />

                <p className={'text-sm italic text-grey-900'}>(Dit object wordt zonder tussenkomst van een lening aan een investeerder getoond. Mogelijk zal niet alle data beschikbaar zijn)</p>

                <ErrorMessage message={form?.errors?.collateral_for_loan} />
            </div>

            <Box className={'mt-10 flex gap-5'}>
                <Button onClick={handleApplicationEditor}>
                    <PencilSquareIcon width={24} />
                    <span className={'font-bold'}>Eigendom {collateral_object ? 'wijzigen' : 'toevoegen'}</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Modal>
    );
};

export default ApplicationCollateralObjectEditorModal;
